import { merge } from 'lodash'
import contentSettings from '~/sites/nobilis/content.settings'

const overloadSettings = {
  foxentry: {
    id: 'RzHHK7R5Vh',
    routes: ['RouteRegister', 'RouteCheckoutInfo'],
  },
}

export default merge(contentSettings, overloadSettings)
