import { actions, mutations, state as parentState } from '~/sites/nobilis/store/globals'

const state = () =>
  Object.assign(parentState(), {
    forms: {
      businessTypes: ['b2c', 'b2b'],
      countryCode: 'SVK',
      formalTitles: ['Mr', 'Mrs'],
    },
  })

export default { actions, state, mutations }
